import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223')
];

export const server_loads = [2,4,8,13,14,10,11,12,19,21,23,27,31,32,29,30,57,59,60,35,36,37,39,40,43,44,47,51,55,53,54];

export const dictionary = {
		"/(app)": [~62,[2],[3]],
		"/(app)/[[lang=lang]]/(main)/admin": [~63,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants": [64,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]": [65,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]": [66,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/contact-history": [67,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/curriculum-vitae": [68,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/applicants/[type]/[id]/jobs": [69,[2,4,6,8],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers": [70,[2,4,6,9],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores": [83,[2,4,6,9],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]": [~84,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees": [85,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/employees/[userId]": [86,[2,4,6,14],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/job-history": [87,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/open-jobs": [88,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/stores/[id]/points-of-contact": [89,[2,4,6,13],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]": [~71,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/job-postings": [72,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/points-of-contact": [73,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/price-catalog": [~74,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/store-managers": [75,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores": [76,[2,4,6,10],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]": [~77,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees": [78,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/employees/[userId]": [79,[2,4,6,12],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/job-history": [80,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/open-jobs": [81,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/customers/[id]/stores/[storeId]/points-of-contact": [82,[2,4,6,11],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/dashboard": [90,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings": [91,[2,4,6,15],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]": [92,[2,4,6,15],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]": [93,[2,4,6,16],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]/messages": [94,[2,4,6,16],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/job-postings/[type=job_posting_type]/[id]/points-of-contact": [95,[2,4,6,16],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings": [~96,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/admins": [97,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/products": [98,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/platform-settings/products/[productId]": [~99,[2,4,6,17],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/profile": [~100,[2,4,6],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters": [101,[2,4,6,18],[3,5,7]],
		"/(app)/[[lang=lang]]/(main)/admin/recruiters/teams": [102,[2,4,6,18],[3,5,7]],
		"/(app)/agb": [218,[2],[3]],
		"/(app)/[[lang=lang]]/auth": [~203,[2,56],[3]],
		"/(app)/[[lang=lang]]/auth/confirm": [~204,[2],[3]],
		"/(app)/[[lang=lang]]/auth/set-password": [~205,[2,56],[3]],
		"/(app)/[[lang=lang]]/auth/sign-out": [~206,[2,56],[3]],
		"/(app)/[[lang=lang]]/(main)/common/applicants": [103,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/applicants/[id]": [104,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/certify-hub/trackings": [105,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/customers/[customerId]": [106,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/cv": [107,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/employees": [108,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings": [109,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/messages": [111,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/points-of-contact": [112,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/job-postings/[id]": [110,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/points-of-contact": [113,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/profiles": [114,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/common/stores/[id]": [115,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/customer": [~116,[2,4,19],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/company-settings": [~117,[2,4,19,21],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/dashboard": [118,[2,4,19],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs": [119,[2,4,19],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub": [~120,[2,4,19,22],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/locations/[storeId]": [~123,[2,4,19,22],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]": [~121,[2,4,19,22,23],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/certify-hub/[courseId]/[videoId]": [~122,[2,4,19,22,23],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass": [~124,[2,4,19,24],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/checkout": [127,[2,4,19,24],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/[type=health_pass_type]": [125,[2,4,19,24],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/hubs/health-pass/[userId]": [126,[2,4,19,24],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/profile": [~128,[2,4,19],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting": [~129,[2,4,19,25],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/accepted": [130,[2,4,19,25],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings": [~131,[2,4,19,25],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]": [132,[2,4,19,26],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants": [~133,[2,4,19,26],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/applicants/[applicant_id]": [134,[2,4,19,27],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/recruiting/job-postings/[id]/messages": [135,[2,4,19,26],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores": [136,[2,4,19,28],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/employees": [142,[2,4,19,28],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/employees/[id]": [143,[2,4,19,31],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers": [144,[2,4,19,28],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]": [145,[2,4,19,32],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/store-managers/[userId]/stores": [146,[2,4,19,32],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]": [~137,[2,4,19,29],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/employees": [138,[2,4,19,29],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/employees/[userId]": [139,[2,4,19,30],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/job-history": [140,[2,4,19,29],[3,5,20]],
		"/(app)/[[lang=lang]]/(main)/customer/stores/[id]/open-jobs": [141,[2,4,19,29],[3,5,20]],
		"/(app)/datenschutz": [219,[2],[3]],
		"/(app)/[[lang=lang]]/health-pass": [~207,[2,57],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/certificate": [~208,[2,57,59],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/certificate/download": [~209,[2,57,59],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz": [210,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz/answering": [211,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz/answering/review": [~213,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz/answering/success": [~214,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz/answering/[question=question]": [~212,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/quiz/video": [~215,[2,57,60],[3,58]],
		"/(app)/[[lang=lang]]/health-pass/verification": [~216,[2,57],[3,58]],
		"/pdfs/[[lang=lang]]/cv": [~220,[61]],
		"/pdfs/[[lang=lang]]/health-pass-certificate": [~221,[61]],
		"/pdfs/[[lang=lang]]/job-one-pager": [~222,[61]],
		"/pdfs/[[lang=lang]]/letter-of-intent": [~223,[61]],
		"/(app)/[[lang=lang]]/(main)/recruiter": [~147,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants": [148,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]": [149,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]": [150,[2,4,33,35],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/contact-history": [151,[2,4,33,35],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/curriculum-vitae": [152,[2,4,33,35],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/applicants/[type]/[id]/jobs": [153,[2,4,33,35],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers": [154,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]": [155,[2,4,33,36],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]/stores": [156,[2,4,33,36],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/customers/[id]/stores/[storeId]": [157,[2,4,33,37],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/dashboard": [158,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings": [159,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]": [160,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]": [161,[2,4,33,38],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants": [~162,[2,4,33,38],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]": [163,[2,4,33,39],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/contact-history": [164,[2,4,33,39],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/applicants/[applicant_id]/jobs": [165,[2,4,33,39],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/messages": [166,[2,4,33,38],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/job-postings/[type=job_posting_type]/[id]/points-of-contact": [167,[2,4,33,38],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/recruiter/profile": [~168,[2,4,33],[3,5,34]],
		"/(app)/[[lang=lang]]/(main)/redirect": [169,[2,4],[3,5]],
		"/(app)/[[lang=lang]]/(main)/store-employee": [170,[2,4,40],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub": [~171,[2,4,40,42],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub/[courseId]": [~172,[2,4,40,42,43],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-employee/certify-hub/[courseId]/[videoId]": [~173,[2,4,40,42,43],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-employee/dashboard": [~174,[2,4,40],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-employee/profile": [~175,[2,4,40],[3,5,41]],
		"/(app)/[[lang=lang]]/(main)/store-manager": [~176,[2,4,44],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/dashboard": [177,[2,4,44],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs": [178,[2,4,44],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub": [~179,[2,4,44,46],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/locations/[storeId]": [~182,[2,4,44,46],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]": [~180,[2,4,44,46,47],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/certify-hub/[courseId]/[videoId]": [~181,[2,4,44,46,47],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass": [~183,[2,4,44,48],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/checkout": [186,[2,4,44,48],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[type=health_pass_type]": [184,[2,4,44,48],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/hubs/health-pass/[userId]": [185,[2,4,44,48],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/profile": [~187,[2,4,44],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting": [~188,[2,4,44,49],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/accepted": [189,[2,4,44,49],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings": [~190,[2,4,44,49],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]": [191,[2,4,44,50],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants": [~192,[2,4,44,50],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/applicants/[applicant_id]": [193,[2,4,44,51],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/recruiting/job-postings/[id]/messages": [194,[2,4,44,50],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores": [195,[2,4,44,52],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/employees": [201,[2,4,44,52],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/employees/[id]": [202,[2,4,44,55],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]": [~196,[2,4,44,53],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/employees": [197,[2,4,44,53],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/employees/[userId]": [198,[2,4,44,54],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/job-history": [199,[2,4,44,53],[3,5,45]],
		"/(app)/[[lang=lang]]/(main)/store-manager/stores/[id]/open-jobs": [200,[2,4,44,53],[3,5,45]],
		"/(app)/[lang=lang]": [~217,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';